import React from "react";
import { Router} from '@reach/router'
import List from "../pages/list";

const App = () => (
  <Router>
    <FilteredList path="search/:string" />
  </Router>
);

const FilteredList = ({ string }) => {
  return <>
    <List searchInput={string} hideSearchButton />
  </>
}

export default App;